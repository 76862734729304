<script>
    import { onMount } from 'svelte'

    const baseURL = process.env.BASE_URL;

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state')
    
    let serverData = null;
    let errorMessage = null;

    const callbackEndpoint = new URL(baseURL+'/rso/callback')
    callbackEndpoint.searchParams.set("code", code)
    callbackEndpoint.searchParams.set("state", state)

    // Function to fetch data from the server-side API
    const fetchData = async () => {
        try {
            const response = await fetch(callbackEndpoint);

            if (!response.ok) {
                console.log(response.statusText)
                const errorText = await response.statusText;
                throw new Error(`Error ${response.status}: ${errorText}`);
            }

            serverData = await response.json();
        } catch (error) {
            errorMessage = error.message
        }
    };

    // Fetch data when the component is mounted
    onMount(() => {
        fetchData();
    });
</script>

<main>
    {#if errorMessage}
        <h1>Error</h1>
        <p>{errorMessage}</p>
    {:else if serverData}
        <h1>Riot Login Successful</h1>
        <p>You can now join quests and tournaments on your Discord channel!</p>
    {:else}
        <h1>Loading...</h1>
        <p>Please wait while we process your request.</p>
    {/if}
</main>
