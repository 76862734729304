<script>
    import {Route, Router} from 'svelte-routing';
    import Navbar from "./Navbar.svelte";
    import Home from './Home.svelte';
    import RSOLogout from './RSO_logout.svelte';
    import RsoCallback from './RSO_callback.svelte';
    import Terms from './Terms.svelte';
    import Privacy from './Privacy.svelte';

</script>

<Router>
    <header>
        <Navbar/>
    </header>
    <body>
        <Route path="/" component={Home}/>
        <Route path="/home" component={Home}/>
        <Route path="/rso-logout" component={RSOLogout}/>
        <Route path="/rso-callback" component={RsoCallback}/>
        <Route path="/terms" component={Terms}/>
        <Route path="/privacy" component={Privacy} />
        <Route path="*" component={Home}/>
    </body>
</Router>