<script>
    // TODO: callback method 
    /*import { onMount } from 'svelte';

    let serverData = null;
    const callbackEndpoint = 'http://www.localhost:8081/rso/callback'

    // Function to fetch data from the server-side API
    const fetchData = async () => {
        const response = await fetch(callbackEndpoint);
        const result = await response.json();
        serverData = result;
    };

    // Fetch data when the component is mounted
    onMount(() => {
        fetchData();
    });*/
</script>

<main>
    <h1>Riot Login Successful</h1>
    
    <p>You can close this window</p>
</main>
