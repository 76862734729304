<script>
    import { Link, navigate } from 'svelte-routing';

    // Function to scroll to the features section
    const scrollToFeatures = (event) => {
        event.preventDefault();
        const featuresSection = document.getElementById('features');
        
        if (featuresSection) {
            featuresSection.scrollIntoView({ behavior: 'smooth' });
        } else {
            // If the user is not on the home page, navigate to home first and then scroll
            navigate("/home").then(() => {
                setTimeout(() => {
                    document.getElementById('features').scrollIntoView({ behavior: 'smooth' });
                }, 100); // Allow time for the navigation to complete
            });
        }
    };

</script>

<nav class="navbar navbar-expand-lg navbar-dark bg-discord-dark">
    <a class="navbar-brand" href="/">
        <img src="/assets/images/summoners_battle_logo.png" class="d-inline-block align-top rounded-circle logo" alt="Logo Summoners Battle">
        Summoners Battle
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <Link class="nav-link" to="/home"><i class="fas fa-home"></i> Home</Link>
            </li>
            <li class="nav-item">
                <a href="#features" class="nav-link" on:click={scrollToFeatures}><i class="fas fa-info-circle"></i> Features</a>
            </li>
            <li class="nav-item">
                <Link class="nav-link" to="/terms"><i class="fas fa-envelope"></i> Terms</Link>
            </li>
            <li class="nav-item">
                <Link class="nav-link" to="/privacy"><i class="fas fa-envelope"></i> Privacy</Link>
            </li>
        </ul>
    </div>
</nav>