<script>
    let features = [
        {
            icon: "🥇",
            title: "Weekly Competitions",
            description: "Engage your community with automatic weekly competitions, encouraging players to compete for the top spot in both Ranked and ARAM games."
        },
        {
            icon: "💬",
            title: "Boost Community Engagement",
            description: "Drive increased activity in your Discord server with friendly, skill-based competitions, sparking discussions and strengthening player connections."
        },
        {
            icon: "📢",
            title: "Winner Announcements",
            description: "Celebrate the champions! Automatically announce competition winners to the entire server, boosting their recognition and status among peers."
        },
        {
            icon: "✨",
            title: "Customizable Bot Features",
            description: "Easily configure which channels the bot uses for notifications, ensuring seamless integration without disrupting other conversations."
        }

    ];
</script>

<section class="landing-page">
    <div class="landing-text">
        <h1>Summoners Battle</h1>
        <p class="big-paragraph">A Discord bot designed to enhance your gaming experience by creating friendly competitions among Discord users based on their League of Legends stats.</p>
    </div>
    <div class="landing-image">
        <!-- svelte-ignore a11y-img-redundant-alt -->
        <img src="/assets/images/home-image.jpeg" alt="Summoners Battle Discord Bot Image" />
    </div>
</section>

<section id="features">
    <h2>FEATURES</h2>
    <div class="features-grid">
        {#each features as feature}
            <div class="feature-card">
                <div class="icon-circle">
                    <span class="icon">{feature.icon}</span>
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
            </div>
        {/each}
    </div>
</section>